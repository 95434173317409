import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../../images/icons/close.png';

import './BoksnokModal.css';

const BoksnokModal = ({ children, className, show, onClose }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      centered
      dialogClassName={`boksnok-dialog${className ? ` ${className}` : ''}`}
      show={show}
      onHide={onClose}
    >
      <div className="dialog-top">
        {/* eslint-disable-next-line */}
        <img alt="Stäng dialogen" className="closeIcon" src={closeIcon} onClick={handleClose} />
      </div>

      <Modal.Body>
        <div className="dialog-body">{children}</div>
      </Modal.Body>
    </Modal>
  );
};

BoksnokModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BoksnokModal;
