import React from 'react';

import SEO from '../seo';

class BaseEmpty extends React.Component {
  render() {
    const { addPath, redirect } = this.props;
    return <SEO title="Boksnok" {...{ addPath, redirect }} />;
  }
}

export default BaseEmpty;
