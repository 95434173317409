import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { BaseModal } from './index';
import { updatePassword } from '../../../lib/api';
import { getSessionId } from '../../../lib/storage';

const ChangePasswordModal = ({ show, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      if (!oldPassword) {
        alert('Du måste fylla i ditt gamla lösenord');
        return false;
      }

      if (newPassword.length < 8) {
        alert('Se till att ditt lösenord är minst 8 tecken långt');
        return false;
      }

      if (newPassword !== passwordConfirm) {
        alert('Lösenorden stämmer inte överens');
        return false;
      }

      await updatePassword(oldPassword, newPassword, getSessionId());
      setOldPassword('');
      setNewPassword('');
      setPasswordConfirm('');
      onClose(true);
    } catch (ex) {
      alert(ex.message + " " + (ex.fields.password ? ex.fields.password: ""));
    } finally {
      setWaiting(false);
    }
  };

  const handleClose = () => {
    setOldPassword('');
    setNewPassword('');
    setPasswordConfirm('');
    onClose(false);
  };

  return (
    <BaseModal
      title="Ändra lösenord"
      show={show}
      waiting={waiting}
      onClose={handleClose}
      onSave={handleSave}
    >
      Fyll i ditt nuvarande lösenord:
      <InputGroup className="mb-3">
        <FormControl
          aria-label="current password"
          placeholder="Ditt gamla lösenord"
          type="password"
          value={oldPassword}
          onChange={({ target: { value } }) => setOldPassword(value)}
        />
      </InputGroup>
      Skriv in ditt nya lösenord:
      <InputGroup className="mb-3">
        <FormControl
          aria-label="new password"
          placeholder="Ditt nya lösenord"
          type="password"
          value={newPassword}
          onChange={({ target: { value } }) => setNewPassword(value)}
        />
      </InputGroup>
      Bekräfta det nya lösenordet:
      <InputGroup className="mb-3">
        <FormControl
          aria-label="confirm new password"
          placeholder="Ditt nya lösenord igen"
          type="password"
          value={passwordConfirm}
          onChange={({ target: { value } }) => setPasswordConfirm(value)}
        />
      </InputGroup>
    </BaseModal>
  );
};

export default ChangePasswordModal;
