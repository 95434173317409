import React from 'react';
import PropTypes from 'prop-types';

import appStoreDeu from '../../images/appstore-de.png';
import appStoreSwe from '../../images/appstore.png';
import googlePlaySwe from '../../images/googleplay.png';
import googlePlayDeu from '../../images/google-play-badge.svg';
import appStoreLightSwe from '../../images/appstore.svg';
import appStoreLightDeu from '../../images/appstore-deu-light.svg';
import googlePlayLightSwe from '../../images/google.svg';
import googlePlayLightDeu from '../../images/googleplay-deu-light.svg';

const { STORELOGOS } = require('../../../config');

const IMAGES = {
  SWE: {
    APPLE: appStoreSwe,
    GOOGLE: googlePlaySwe,
    APPLE_LIGHT: appStoreLightSwe,
    GOOGLE_LIGHT: googlePlayLightSwe
  },
  DEU: {
    APPLE: appStoreDeu,
    GOOGLE: googlePlayDeu,
    APPLE_LIGHT: appStoreLightDeu,
    GOOGLE_LIGHT: googlePlayLightDeu
  }
}

const AppLogo = ({ className, region, trackerName, type }) => {
  const appLogo = STORELOGOS[type];
  console.log("Applogo: ", appLogo.linkIdSuffix);
  return (
    <a
      id={`${trackerName}${appLogo.linkIdSuffix}`}
      href={appLogo.href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        alt={appLogo.alt}
        className={className}
        id={`${trackerName}${appLogo.imageIdSuffix}`}
        src={IMAGES[region][type]}
      />
    </a>
  );
};

AppLogo.propTypes = {
  className: PropTypes.string,
  trackerName: PropTypes.string.isRequired,
  type: PropTypes.string,
  region: PropTypes.string
};

export default AppLogo;
