module.exports = {
  'siteMetadata.title': 'Lylli | Tausende Ebooks und Hörbücher für Kinder',
  'siteMetadata.description': 'Willkommen bei Lylli! Hier kannst du zwischen vielen Kinderbüchern auswählen, dir diese von einem Erzähler vorlesen lassen und selbst offline immer dabei haben.',
  'head.title': 'Lylli | Tausende Ebooks und Hörbücher für Kinder',
  
  'link.support': 'kontakt@lylli.de',
  'link.klarna': 'https://www.klarna.com/se/klarna-app/',
  'link.inviteFriend': '',
  'link.faq': 'https://www.lylli.de/fragen-und-antworten',
  'link.createAccount' : 'https://www.lylli.de/register/create-account',
  
  'login.title': 'Einloggen',
  'login.email-label': 'Mail-Adresse',
  'login.email-placeholder': 'Gib deine Mail-Adresse an',
  'login.password-label': 'Dein passwort',
  'login.password-placeholder': 'Gib dein Passwort an',
  'login.submit':'Einloggen',
  'login.submit-error': 'Falsche E-Mail-Adresse oder Passwort.',
  'login.createAccount':'Konto anlegen',
  'login.resetPassword':'Passwort vergessen?',
  'login.loading': 'Einloggen...',

  'resetPassword.title':'Passwort vergessen',
  'resetPassword.text': 'Gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.',
  'resetPassword.email-placeholder': 'Gib deine Mail-Adresse an',
  'resetPassword.submit': 'Passwort zurücksetzen',
  'resetPassword.submit-confirmed':'Ein Wiederherstellungslink wurde an deine E-Mail gesendet.',

  'password.title' : 'Zurücksetzen des Passwortes',
  'password.text' : 'Du bist dabei, dein Passwort zu ändern.',
  'password.password-label': 'Wähle ein neues Passwort',
  'password.repeatPassword-label': 'Wiederhole dein neues Passwort',
  'password.submit': 'Ändere Passwort',
  'password.submit-confirmed': 'Dein Passwort wurde nun geändert.',
  'password.submit-errorLength': 'Das Password muss mindestens 8 Zeichen enthalten.',
  'password.submit-errorRepeat': 'Die Passwörter stimmen nicht überein.',

  'shareCode.notFound' : 'Der Code konnte nicht abgerufen werden, bitte versuche es erneut oder wende dich an kontakt@lylli.de',

  'profile.title': 'Dein Profil',
  'profile.welcome-header': 'Hallo!',
  'profile.welcome-subheader': 'Mein Konto',
  'profile.changeBtn': 'Ändern',

  'profile.subscription-header': 'Abonnement',
  'profile.subscription.type-header': 'Abowahl:',
  'profile.subscription.type-inactive': 'Noch nicht ausgewählt',
  'profile.subscription.type-notActivated': 'Nicht aktiviert',

  'profile.subscription.startSub': 'Abonnement starten',
  'profile.subscription.endSub' : (type) => {
    if(type === 'APPLE') {
      return 'Avsluta prenumeration via Apple';
    } else {
      return 'Abo kündigen';
    }
  },
  'profile.subscription.changeSub' : (type) => {
    if (type === 'APPLE') {
      return 'Abo via Apple aktualisieren';
    } else {
      return 'Bezahlmethode aktualisieren';
    }
  },
  'profile.subscription.startTrial': 'Abo starten',
  'profile.subscription.addPayment' : 'Bezahlmethode aktualisieren',
  'profile.subscription.loading' : 'Information über Abonnement lädt...',
  'profile.subscription.offer-header' : 'Angebot:',
  'profile.subscription.addPayment-reactivate': 'Füge eine Zahlungsmethode hinzu und reaktiviere dein Konto für ',
  'profile.subscription.addPayment-freeTrial': {
    text1: 'Füge eine Zahlungsmethode hinzu und lies mit Lylli kostenlos für ',
    text2: ' Tage'
  },
  'profile.subsription.activeDays-header': 'Fortsätt använda Lylli i:',
  'profile.subscription.nextPayment-header': 'Nächster Abbuchungstag:',
  'profile.subscription.trialPeriod-header': 'Probezeit:',
  'profile.subscription.grace-addPayment': 'Wir konnten keine Abbuchung für dein Abo vornehmen. Bitte aktualisiere deine Bezahlmethode, um Lylli weiterhin nutzen zu können.',
  
  'cancelSub-title' : (cancelled) => {
    if (!cancelled) return 'Warum möchtest du dein Abo kündigen?';
    if (cancelled) return 'Dein Abo wurde gekündigt 😢';
  },
  'cancelSub-subtitle' : (cancelled) => {
    if(!cancelled) return 'Abonnement kündigen';
    if(cancelled) return null;
  },
  'cancelSub.chooseReason' : 'Bitte gib einen Grund an, um dein Abo zu kündigen!',
  'cancelSub.stayCTA' : 'Abonnement verlängern',
  'cancelSub.cancelCTA' : 'Kündigen', 
  'cancelSub.endSub-activeUntil' : (date) => {
    return `Du kannst Lylli noch bis ${date} nutzen.`;
  },
  'cancelSub.feedback' : {
    text1: 'Gibt es etwas, das wir verbessern können?',
    text2: 'Wir freuen uns über jegliches Feedback!'
  },
  'cancelSub.feedback-sendCTA': 'Feedback schicken',
  'cancelSub.feedback-closeCTA': 'Schließen',
  'cancelSub.confirmation' : {
    text1: 'Hoffentlich sehen wir uns bald wieder ',
    text2: 'Das Lylli-Team'
  },

  'cancelSub.reasons' : [
    { id: 'content-not-good-enough', label: 'Die Bücher, die ich lesen/ anhören möchte, gibt es nicht'},
    { id: 'reduce-costs', label: 'Die Kosten sind zu hoch' },
    { id: 'had-technical-issues', label: 'Ich habe Probleme mit der App' },
    { id: 'try-other-service', label: 'Ich probiere anstelle eine andere App aus' },
    { id: 'only-wanted-to-test', label: 'Ich möchte nur am Angebot teilnehmen' },
    { id: 'below-expectations', label: 'Die App entspricht nicht meinen Vorstellungen' },
    { id: 'others-no-reason', label: 'Anderer Grund' },
  ],

  'changeSub.updatedSub': (type) => {
    if(type === '1') {
      return 'Dein Abonnement wurde jetzt umgestellt zu einem Monatsabonnement';
    } else {
      return 'Dein Abonnement wurde jetzt umgestellt zu einem Jahresabonnement';
    }
  },
  'changeSub.tipCTA': {
    text1: '',
    text2: 'Wechsle zu einem Jahresabonnement und spare 40 %.'
  },
  'changeSub.savings': 'Du sparst 40%',

  'profile.payment-header': 'Bezahlung',
  'profile.payment.type-header': 'Bezahlmethode:',
  'profile.payment.type-missing': 'Saknas',
  'profile.payment.type-card': 'Kreditkarte',

  'profile.account-header': 'Konto',
  'profile.account.accountSince-header': 'Kunde seit:',
  'profile.account.email-header': 'E-Mail-Adresse:',
  'profile.account.password-header': 'Passwort:',

  'profile.download-header': 'Lylli downloaden',
  'profile.download-text': 'Lylli gibt es für iOS und Android. Die App kann auf dem Handy und Tablet benutzt werden.',

  'profile.help-header': 'Brauchst du Hilfe?',
  'profile.help.faq': 'FAQ',
  'profile.help.support': 'Kontakt',

  'inviteFriend-header': 'Lade einen Freund ein!',
  'inviteFriend-text': 'Lade einen Freund ein, damit dein Freund Lylli 30 Tage lang ausprobieren kann! Teile den untenstehenden Code.',
  'inviteFriend-textCopied': (copy) => {
    if(copy) return 'Kopiert!'
    return'Kopieren'
  },

  'apple-step1': '1. Öffne “Einstellungen” auf deinem Gerät.',
  'apple-step2': '2. Klicke auf deinen Namen.',
  'apple-step3': '3. Klicke auf “Abonnements”.',
  'apple-step4': '4. Klicke auf Lylli.',

  'apple.cancelSubscription-header': 'So kündigst du dein Abo:',
  'apple.cancelSubscription-step5': '5. Klicke auf “Abo kündigen”.',

  'apple.handleSubscription-header': 'So wechselst du dein Abo:',
  'apple.handleSubscription-step5': '5. Wähle ein Abonnement.',

  'apple.changeSubscription-header': 'Du hast Apple als Bezahlmethode angegeben',
  'apple.changeSubscription-text1': 'Um deine Bezahlmethode ändern zu können, musst du zunächst dein Lylli-Abo bei Apple kündigen. Dann kannst dein Abo hier via “Mein Konto” aktualisieren und eine neue Bezahlmethode hinzufügen.',
  'apple.changeSubscription-text2' : 'Lylli-Abo bei Apple kündigen:',
  
  'apple.getSupport': 'Brauchst du Hilfe? Schreibe uns eine Mail an ',

  'receipts.klarna' : {
    text1: 'Logge dich in die ',
    linkText: 'Klarna-app',
    text2: ' ein um deine Quittungen zu sehen.'
  },

  'receipts.contactSupport': {
    text1: 'Kontaktiere unseren Kundensupport via ',
    text2: ' um deine Quittungen zu sehen.'
  },

  'giftcard': {
    text1: 'Du möchtest deinen Geschenkegutschein einlösen, yay! Kontaktiere unseren Kundensupport via ',
    text2: ', dann helfen wir dir dabei.'
  },

  'modido.changeCard-header': 'Verwalte deine Bezahlung ',
  'modido.changeCard-text': 'Hier fügst du die Karte hinzu, mit der du Lylli abonnieren möchtest.',

  'accountUpdated': 'Konto aktualisiert',

  'subscription.activeInDays': (days) => `${days} dag${days === 1 ? '' : 'ar'}`,

  'base.save': 'Sparen',
  'base.close': 'Schließen',

  'footer.title1': 'Lerne uns kennen',
  'footer.aboutUs': {
    title: 'Über uns',
    link: '/uber'
  },
  'footer.press': {
    title: 'Presse',
    link: 'https://presse.lylli.de'
  },
  'footer.faq' : {
    title: 'Fragen und Antworten',
    link: '/fragen-und-antworten'
  },
  'footer.work' : {
    title: 'Karriär',
    link: 'https://jobs.lylli.se/'
  },
  'footer.conditions': {
    title: 'Allmänna villkor',
    link: '/allmanna-villkor'
  },
  'footer.cookies': {
    title: 'Cookies',
    link: '/cookies'
  },
  'footer.privacyPolicy': {
    title : 'Personuppgiftspolicy',
    link: '/personuppgiftspolicy'
  },
  'footer.title2':'Kom igång',
}
