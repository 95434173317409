import React from "react"
import Col from "react-bootstrap/Col"

export default ({ children, title }) => (
  <Col xs={12} sm={4} className="footer-col">
    <div style={{ margin: "auto", display: "inline-block" }}>
      <div style={{ margin: "auto", textAlign: "left" }}>
        <h4 className="footer-row-header">{title}</h4>
        <div className="footer-row-body">{children}</div>
      </div>
    </div>
  </Col>
)
