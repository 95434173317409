import React from "react";
import Modal from "react-bootstrap/Modal";

import { ButtonOld } from "../";
import tr from '../../../../translations';

const BaseModal = ({ children, show, title, waiting, onClose, onSave, disabled }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <ButtonOld disabled={waiting} variant="secondary" onClick={onClose}>
          {tr('base.close')}
        </ButtonOld>
        <ButtonOld disabled={disabled ? disabled : waiting} variant="primary" onClick={onSave}>
          {tr('base.save')}
        </ButtonOld>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal;
