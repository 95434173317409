import React from 'react';

import { Navbar } from './index';

const Header = (props) => {
  return (
    <header>
      <Navbar {...props} />
    </header>
  );
};

export default Header;
