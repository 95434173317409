module.exports = {
  'siteMetadata.title' : 'Lylli | Tusentals e-böcker och ljudböcker för barn',
  'siteMetadata.description' : 'Lylli är appen som väcker barnens läslust i en digital värld. Tusentals fängslande böcker med röster, ljud och animationer för en ännu roligare läsupplevelse.',
  'head.title' : 'Lylli | Tusentals e-böcker och ljudböcker för barn',

  'link.support': 'kontakt@lylli.se',
  'link.klarna': 'https://www.klarna.com/se/klarna-app/',
  'link.inviteFriend': 'https://www.lylli.se/kompis/?code=',
  'link.faq': 'https://www.lylli.se/vanliga-fragor',
  'link.createAccount' : 'https://www.lylli.se/konto/skapa-konto',

  'login.title' : 'Logga in',
  'login.email-label' : 'E-post',
  'login.email-placeholder' : 'Fyll i din e-post',
  'login.password-label' : 'Ditt lösenord',
  'login.password-placeholder' : 'Lösenord',
  'login.submit' : 'Logga in',
  'login.submit-error' : 'Felaktigt e-postadress eller lösenord.',
  'login.createAccount' : 'Skapa konto',
  'login.resetPassword' : 'Glömt lösenord?',
  'login.loading': 'Loggar in...',
  
  'resetPassword.title' : 'Glömt lösenord',
  'resetPassword.text' : 'För att återställa ditt lösenord, fyll i din e-post.',
  'resetPassword.email-placeholder' : 'E-post',
  'resetPassword.submit' : 'Återställ lösenord',
  'resetPassword.submit-confirmed' : 'En återställningslänk har skickats till din e-post.',

  'password.title' : 'Återställ lösenord',
  'password.text' : 'Du är på väg att byta ditt lösenord.',
  'password.password-label': 'Välj nytt lösenord',
  'password.repeatPassword-label': 'Upprepa lösenord',
  'password.submit': 'Byt lösenord',
  'password.submit-confirmed': 'Ditt lösenord är nu ändrat.',
  'password.submit-errorLength': 'Lösenordet måste vara minst 8 tecken.',
  'password.submit-errorRepeat': 'Lösenorden matchar inte varandra.',

  'shareCode.notFound' : 'Koden kunde inte hämtas, vänligen försök igen eller kontakta kontakt@lylli.se',

  'profile.title': 'Din profil',
  'profile.welcome-header': 'Hejsan!',
  'profile.welcome-subheader': 'Mina sidor',
  'profile.changeBtn': 'Ändra',

  'profile.subscription-header': 'Prenumeration',
  'profile.subscription.type-header': 'Prenumerationstyp:',
  'profile.subscription.type-inactive': "Inaktiv",
  'profile.subscription.type-notActivated': "Ej aktiverad",

  'profile.subscription.startSub': 'Starta prenumeration',
  'profile.subscription.endSub' : (type) => {
    if(type === 'APPLE') {
      return 'Avsluta prenumeration via Apple';
    } else {
      return 'Avsluta prenumeration';
    }
  },
  'profile.subscription.changeSub' : (type) => {
    if (type === 'APPLE') {
      return 'Hantera prenumeration via Apple';
    } else {
      return 'Byt prenumeration';
    }
  },
  'profile.subscription.startTrial': 'Starta provperiod',
  'profile.subscription.addPayment' : 'Lägg till betalmetod',
  'profile.subscription.loading' : 'Laddar prenumerationsinfo...',
  'profile.subscription.offer-header' : 'Erbjudande:',
  'profile.subscription.addPayment-reactivate': 'Lägg till en betalmetod och återaktivera ditt konto för',
  'profile.subscription.addPayment-freeTrial': {
    text1: 'Lägg till en betalmetod och läs med Lylli gratis i ',
    text2: ' dagar'
  },
  'profile.subsription.activeDays-header': 'Fortsätt använda Lylli i:',
  'profile.subscription.nextPayment-header': 'Nästa betalning sker:',
  'profile.subscription.trialPeriod-header': 'Provperiod:',
  'profile.subscription.grace-addPayment': 'Vi har inte lyckats debitera dig för din prenumeration. Uppdatera din betalmetod för att fortsätta läsa med Lylli.',
  
  'cancelSub-title' : (cancelled) => {
    if (!cancelled) return 'Varför vill du säga upp din prenumeration?';
    if (cancelled) return 'Din prenumeration är nu avslutad 😢';
  },
  'cancelSub-subtitle' : (cancelled) => {
    if(!cancelled) return 'Avsluta prenumeration';
    if(cancelled) return null;
  },
  'cancelSub.chooseReason' : 'Du behöver välja ett alternativ för att avsluta!',
  'cancelSub.stayCTA' : 'Stanna kvar',
  'cancelSub.cancelCTA' : 'Avsluta', 
  'cancelSub.endSub-activeUntil' : (date) => {
    return `Du kan fortsätta använda Lylli tills ${date}.`;
  },
  'cancelSub.feedback' : {
    text1: 'Finns det något vi skulle kunna göra bättre?',
    text2: 'Vi är tacksamma för all feedback.'
  },
  'cancelSub.feedback-sendCTA': 'Skicka feedback',
  'cancelSub.feedback-closeCTA': 'Stäng',
  'cancelSub.confirmation' : {
    text1: 'Hoppas vi ses snart igen ',
    text2: 'Lylligänget'
  },

  'cancelSub.reasons' : [
    { id: 'content-not-good-enough', label: 'Böckerna jag vill läsa/lyssna på saknas'},
    { id: 'reduce-costs', label: 'Kostnaden är för hög' },
    { id: 'had-technical-issues', label: 'Jag upplever problem med appen' },
    { id: 'try-other-service', label: 'Jag ska prova en annan tjänst istället ' },
    { id: 'only-wanted-to-test', label: 'Jag ville bara ta del av erbjudandet' },
    { id: 'below-expectations', label: 'Tjänsten motsvarade inte mina förväntningar' },
    { id: 'others-no-reason', label: 'Annan anledning' },
  ],

  'changeSub.updatedSub': (type) => {
    if(type === '1') {
      return 'Nu är din prenumeration uppdaterat till en månadsprenumeration';
    } else {
      return 'Nu är din prenumeration uppdaterat till en årsprenumeration';
    }
  },
  'changeSub.tipCTA': {
    text1: 'Tips:',
    text2: ' byt till en årsprenumeration och spara 40%'
  },
  'changeSub.savings': 'Spara 40%',


  'profile.payment-header': 'Betalning',
  'profile.payment.type-header': 'Betalmetod:',
  'profile.payment.type-missing': 'Saknas',
  'profile.payment.type-card': 'Kreditkort',

  'profile.account-header': 'Konto',
  'profile.account.accountSince-header': 'Kund sedan:',
  'profile.account.email-header': 'Mejladress:',
  'profile.account.password-header': 'Lösenord:',

  'profile.download-header': 'Ladda ner Lylli-appen',
  'profile.download-text': 'Lylli finns för både iOS- och Android. Appen kan användas på mobil och surfplatta.',

  'profile.help-header': 'Behöver du hjälp?',
  'profile.help.faq': 'FAQ',
  'profile.help.support': 'Support',

  'inviteFriend-header': 'Bjud in en kompis!',
  'inviteFriend-text': 'Bjud in en vän så får din kompis prova Lylli gratis i 30 dagar! Dela koden nedan.',
  'inviteFriend-textCopied': (copy) => {
    if(copy) return 'Kopierat!'
    return'Kopiera'
  },

  'apple-step1': '1. Öppna appen Inställningar på den enhet där du skapat prenumerationen.',
  'apple-step2': '2. Tryck på ditt namn.',
  'apple-step3': '3. Tryck på Prenumerationer.',
  'apple-step4': '4. Tryck på Lylli.',

  'apple.cancelSubscription-header': 'Så här går du tillväga för att avsluta din prenumeration.',
  'apple.cancelSubscription-step5': '5. Tryck på Avbryt prenumerationen.',

  'apple.handleSubscription-header': 'Så här går du tillväga för att ändra din prenumeration.',
  'apple.handleSubscription-step5': '5. Välj en ny prenumerationstyp.',

  'apple.changeSubscription-header': 'Du har valt Apple som betalmetod',
  'apple.changeSubscription-text1': 'För att ändra betalmetod måste du för avsluta din Apple prenumeration och sedan återaktivera ditt abonnemang här på Mina Sidor med önskad betalmetod.',
  'apple.changeSubscription-text2' : 'Avsluta Apple prenumeration:',
  
  'apple.getSupport': 'Behöver du hjälp? Kontakta support på ',

  'receipts.klarna' : {
    text1: 'Logga in i ',
    linkText: 'Klarna-appen',
    text2: ' för att se dina kvitton.'
  },

  'receipts.contactSupport': {
    text1: 'Kontakta support på ',
    text2: ' för att få dina kvitton.'
  },

  'giftcard': {
    text1: 'Kul att du vill lösa in ditt presentkort! Kontakta support på ',
    text2: ' så hjälper vi dig.'
  },

  'modido.changeCard-header': 'Hantera din betalning',
  'modido.changeCard-text': 'Här lägger du till det kort som du vill använda för att prenumerera på Lylli.',

  'accountUpdated': 'Kontot uppdaterat',

  'subscription.activeInDays': (days) => `${days} dag${days === 1 ? '' : 'ar'}`,

  'base.save': 'Spara',
  'base.close': 'Stäng',

  'footer.title1': 'Lär känna oss',
  'footer.aboutUs': {
    title: 'Om oss',
    link: '/om-oss'
  },
  'footer.press': {
    title: 'Press',
    link: 'https://press.lylli.se'
  },
  'footer.faq' : {
    title: 'Vanliga frågor',
    link: '/vanliga-fragor'
  },
  'footer.work' : {
    title: 'Karriär',
    link: 'https://jobs.lylli.se/'
  },
  'footer.conditions': {
    title: 'Allmänna villkor',
    link: '/allmanna-villkor'
  },
  'footer.cookies': {
    title: 'Kakor',
    link: '/kakor'
  },
  'footer.privacyPolicy': {
    title : 'Personuppgiftspolicy',
    link: '/personuppgiftspolicy'
  },
  'footer.title2':'Kom igång'
}
