const { REGION } = require('../config');
const Translations = require('./tr.' + REGION.toLowerCase());

module.exports = (key, ...args) => {
  let tr = Translations[key];

  if (!tr) {
    console.trace();
    console.error(`Missing translation key '${key}' in ${REGION} translations file`);
    tr = `<>missing translation: ${key}<>`;
  }

  if (typeof tr === 'function') {
    console.log('Translation by function:', tr(...args));
    return tr(...args);
  }

  console.log('Translation:', tr);
  return tr || '';
}
