import React from 'react';
import Nav from 'react-bootstrap/Nav';

import './NavText.css';

const NavText = ({ className, children, to }) => (
  <Nav.Item>
    <a className={`lylli-text${className ? ` ${className}` : ''}`} href={to}>
      {children}
    </a>
  </Nav.Item>
);

export default NavText;
