import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import FooterColumn from './FooterColumn';

import './Footer.css';
import iconFB from '../../../images/icon-fb.png';
import iconIN from '../../../images/icon-in.png';
import AppLogo from '../AppLogo';
import tr from '../../../../translations';

const {REGION} = require('../../../../config');

const TextLink = ({ children, to, external }) => (
  <p>
    <a href={external ? `${to}` : `https://www.lylli.se${to}`}>{children}</a>
  </p>
);
// make two seperate components for translations i.e. two returns depending on region 
export default () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container className="footer-rows">
        <Row className="footer-row">
          <FooterColumn title={tr('footer.title1')}>
            <TextLink to={tr('footer.aboutUs').link}>{tr('footer.aboutUs').title}</TextLink>
            <TextLink external to={tr('footer.press').link}>
              {tr('footer.press').title}
            </TextLink>
            <TextLink to={tr('footer.faq').link}>{tr('footer.faq').title}</TextLink>
            {REGION === 'SWE' ?
              <TextLink external to={tr('footer.work').link}>
                {tr('footer.work').title}
              </TextLink>
              :
              <></>
            }
            <TextLink to={tr('footer.conditions').link}>{tr('footer.conditions').title}</TextLink>
            <TextLink to={tr('footer.cookies').link}>{tr('footer.cookies').title}</TextLink>
            <TextLink to={tr('footer.privacyPolicy').link}>{tr('footer.privacyPolicy').title}</TextLink>
          </FooterColumn>

          <FooterColumn title={tr('footer.title2')}>
            <TextLink to="/konto/skapa-konto">Skapa konto</TextLink>
            <TextLink to="/laslust">Tips och inspiration</TextLink>
            <p className="stores">
              <AppLogo trackerName="footer" type='APPLE' region={REGION}/>
              <AppLogo trackerName="footer" type='GOOGLE' region={REGION}/>
            </p>
          </FooterColumn>

          <FooterColumn title="Följ oss">
            <p>
              <a href={`https://facebook.com/lylli.appen`} target="_blank" rel="noopener noreferrer">
                <img alt="Facebook logo" className="social-logo" src={iconFB} />
                Facebook
              </a>
            </p>
            <p>
              <a
                href={`https://www.instagram.com/lylli_se/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Instagram logo" className="social-logo" src={iconIN} />
                Instagram
              </a>
            </p>
            <p>
              &nbsp;
              <a href={`mailto:kontakt@lylli.se`} rel="noopener noreferrer" target="_blank">
                <span className="no-hover">@&nbsp;&nbsp;</span>Maila oss
              </a>
            </p>
          </FooterColumn>
        </Row>
      </Container>
    </footer>
  );
};
