import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonOld } from '../';

const InfoModal = ({ children, show, title, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        <ButtonOld variant="primary" onClick={onClose}>
          Ok
        </ButtonOld>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
