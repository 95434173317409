import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { connect } from 'react-redux';

import { BaseModal } from './index';
import { checkEmail } from '../../../lib/utils';
import { updateEmail } from '../../../lib/api';
import { getSessionId } from '../../../lib/storage';
import { userEmailUpdated } from '../../../redux/actions';

const ChangeEmailModal = ({ currentEmail, setEmailAction, show, onClose }) => {
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      if (!checkEmail(email)) {
        alert('E-postadressen du skrivit verkar inte vara en korrekt e-post');
        return false;
      }

      if (email !== emailConfirm) {
        alert('E-post-adresserna stämmer inte överens');
        return false;
      }

      await updateEmail(email, getSessionId());
      setEmailAction(email);
      setEmail('');
      setEmailConfirm('');
      onClose(true);
    } catch (ex) {
      console.error(ex)
      alert(ex.message);
    }
    finally {
      setWaiting(false);
    }
  };

  const handleClose = () => {
    setEmail('');
    setEmailConfirm('');
    onClose(false);
  };

  return (
    <BaseModal
      title={`Ändra e-post ${currentEmail}`}
      show={show}
      waiting={waiting}
      onClose={handleClose}
      onSave={handleSave}
    >
      Fyll i din nya e-post:
      <InputGroup className="mb-3">
        <FormControl
          aria-label="E-post"
          placeholder="E-post"
          type="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </InputGroup>
      Bekräfta den nya e-posten:
      <InputGroup className="mb-3">
        <FormControl
          aria-label="E-post"
          placeholder="E-post"
          type="email"
          value={emailConfirm}
          onChange={({ target: { value } }) => setEmailConfirm(value)}
        />
      </InputGroup>
    </BaseModal>
  );
};

export default connect(({ user }) => ({ currentEmail: user.email }), {
  setEmailAction: userEmailUpdated,
})(ChangeEmailModal);
